
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment-timezone";
import ConfirmationDialog, { ConfirmationDialogOptions } from "@/components/common/ConfirmationDialog.vue";
import { IProduct, IProductDeleteRES } from "@common/product";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

export default Vue.extend({
	name: "Products",
	components: {
		"confirmation-dialog": ConfirmationDialog
	},
	data: () => {
		return {
			search: "" as string,
			tableLoading: false as boolean,
			headers: [
				new TableHeader("Product ID", "start", true, "id"),
				new TableHeader("Title", "start", true, "title"),
				new TableHeader("Date Modified", "start", true, "dateModified"),
				new TableHeader("Category", "start", true, "categoryName"),
				new TableHeader("Actions", "start", false, "actions")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			snackbar: {
				show: false as boolean,
				text: "" as string,
				color: "primary" as string,
			}
		};
	},
	created: async function () {
		this.tableLoading = true;
		this.store.dispatch.changeAppTitle("Products");
		if (this.store.getters.products.length === 0) {
			await this.store.dispatch.fetchProducts();
		}
		this.tableLoading = false;
	},
	computed: {
		formatTime () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("HH:mm DD/MM/YYYY");
		}
	},
	methods: {
		async deleteItem (item: IProduct) {
			try {
				const dialogTitle = "Product delete";
				const dialogMessage = `Are you sure you want to delete "${item.title}" product?`;
				const dialogOptions: ConfirmationDialogOptions = {
					color: "error",
					width: 400,
					agree: {
						text: "Delete",
						color: "error",
						icon: "delete_forever",
					},
					cancel: {
						text: "Cancel",
						color: "secondary",
						icon: "close",
					}
				};
				const deleteConfirmation = await (this.$refs.deleteProduct as InstanceType<typeof ConfirmationDialog>).open(
					dialogTitle,
					dialogMessage,
					dialogOptions
				);
				if (deleteConfirmation) {
					const data: IProduct = item;
					const options: AxiosRequestConfig = {
						method: "POST",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						data,
						url: `${this.store.getters.serverURL}/product/delete`,
					};
					const res: AxiosResponse<IServerRES<IProductDeleteRES>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						if (res.data.payload.succeeded) {
							this.store.dispatch.deleteProduct(item);
						}

						this.snackbar = {
							show: true,
							color: "success",
							text: "Product was succesfully deleted.",
						};
					}
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		}
	}
});
